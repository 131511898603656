<script>
import { Head, Link } from "@inertiajs/inertia-vue3";
import Layout from "@/Pages/Shared/Layout.vue";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
    components: {
        Link,
        Head,
        Splide,
        SplideSlide,
        SplideTrack
    },
    layout: Layout
};
</script>

<template>
    <Splide class="w-full" :has-track="false" aria-label=""
            :options="{ rewind: true, perPage: 3, pagination: false, breakpoints: {640: {perPage: 1},1100: {perPage: 2}} }">
        <SplideTrack>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Babybjörn Bliss Ana Kucağı Oyuncaklı</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#a2d45e"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">07.26.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Çok iyi bir kargolamayla çok yeni bir ürün geldi.3 ay boyunca sorunsuz kullanıp kiralamamızı problemsiz şekilde sonlandırdık.çok memnun kaldığımız için sonrasında da mama sandalyesi kiraladık.çok tavsiye ederim
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                SP
                            </div>
                            <div class="ml-2 text-base font-bold">Selin P.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Mamaroo 4.0 App Ana Kucağı</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#a2d45e"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">03.01.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Ürün gerçekten çok güzel ve temizdi. Bebeğim 2 aylıkken kiraladım sadece ilk ay ilgisini çekti sonrasında ne yazık ki kullanmadı o yüzden iyi ki kiralamışım dedim. Boşuna kullanmayacağı bir şeye o kadar para vermeye gerek yok. Kiralamini de çok iyi bir hizmet sunuyor memnun kaldım. Teşekkürler :)
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                EÖ
                            </div>
                            <div class="ml-2 text-base font-bold">Ezgi Ö.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">BabyBjörn Kanguru Mini</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#a2d45e"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">03.03.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Ürün tertemiz elime ulaştı. Kimi bebekler severken kimi bebekler kanguruları sevmeyebiliyor. Biz de kiralamini sayesinde tecrübe etmek istedik. Bebeğim çok sevdi. Babybjorn harika bir ürün. Kucağımda bebeği kangurusuz çok uzun süre taşıyamıyorum. Kanguru bana eller serbest pek çok işimi yapabilmeyi, bebeğimle yorulmadan yürüyüşe çıkabilmeyi, alisveris yapabilmeyi konforlu ve güvenli bir şekilde sağladı. Kiralama sürecini uzatmayı planladım. Hizmet kalitesi sebebiyle
                            kiralamaniye bu ürünü kullanimimiza sunduğu için sonsuz teşekkürler 🙏
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                AT
                            </div>
                            <div class="ml-2 text-base font-bold">Ayşegül T.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Cybex GB Pockit Plus Kabin Boy Bebek Arabası</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#a2d45e"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">09.29.2022</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Kiralamini’den tatilde kullanmak üzere kabin boy bebek arabası ve kanguru kiraladım. Gezinin yıldızları oldu ikisi de. Güzel bir hizmet. Sürecin başından sonuna kadar ilgi ve alaka çok iyiydi. Ürünler sıfıra yakındı, tertemiz ulaştı elime. Profesyonel yaklaşıyorlar işlerine. Ara ara girip bakıyorum hoşuma giden bir şey varsa kiralasam diye :)
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                HK
                            </div>
                            <div class="ml-2 text-base font-bold">Hatice K.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Globber Scooter Go Up Deluxe</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#a2d45e"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">06.02.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Ürün kullanılmamış, yeni, paketli şekilde geldi. Oğlum severek kullanıyor. Satın alıp çocuğunuz sıkıldığında bir kenara koymak yerine kiralamak çok doğru bir tercih.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                AA
                            </div>
                            <div class="ml-2 text-base font-bold">Ayşe</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Chicco Next2Me Anne Yanı Beşik</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#a2d45e"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">03.01.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Ürün kargo ve kalitesi çok iyiydi, bir aylığına deneme için kiralamıştım çok memnun kalınca süresini uzattım. Herkese tavsiye ederim, bebek eşyalarını alıp zamanı geçince eve yük etmesinler. Teşekkürler Kiralamini.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                RN
                            </div>
                            <div class="ml-2 text-base font-bold">Resmiye N.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Bumbo Multi Seat Bebek Koltuğu</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#a2d45e"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">01.07.2022</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Kiralama deneyimimden oldukça memnun kaldım fikir muhteşem ayrıca her an destek ekibince ulaşabilmemiz de başarılı. Herşey için teşekkür ederim
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                BS
                            </div>
                            <div class="ml-2 text-base font-bold">Beyza S.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Kraft Safe Spin (0-36 kg) 360° Dönebilen İsofix’li</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#a2d45e"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">02.06.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Ürün çok kaliteli. müşteri ilişkileri muazzam. ticari anlayışları oldukça profesyonel. kesinlikle çok memnun kaldım. ihtiyaçlarımı kiralamini den karşılamaya devam edeceğim.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                DT
                            </div>
                            <div class="ml-2 text-base font-bold">Dursun T.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Fisher Price Eğitici Köpekçiğin Aktivite Masası</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#a2d45e"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">03.20.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Böyle bir kiralama deneyimini bebeğimin oyuncağı olarak yapmak çok zevkliydi. Bu hizmet için çok teşekkür ederim. Ürün temiz geldi ve kutusunda geldi güzel düşünülmüş bi detay. 1 ay için kiraladık 3 ay şeklinde bizde kaldı. Şimdi başka bir şey kiralayacağız 🙂
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                SY
                            </div>
                            <div class="ml-2 text-base font-bold">Sedef Y.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Kraft Plaın Park Yatak</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#a2d45e"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#a2d45e"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">04.09.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Gelen ürün tertemizdi. Ne ödemede ne teslimatta sıkıntılı bir şey oldu. Sipariş verdim ertesi gün elimdeydi.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                SG
                            </div>
                            <div class="ml-2 text-base font-bold">Sibel G.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>

        </SplideTrack>

        <div class="splide__arrows">
            <button class="splide__arrow splide__arrow--prev !-left-9">
                <svg class="" xmlns="http://www.w3.org/2000/svg" width="11.926" height="20.737"
                     viewBox="0 0 11.926 20.737">
                    <path id="Path_18" data-name="Path 18"
                          d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z"
                          transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                </svg>
            </button>
            <button class="splide__arrow splide__arrow--next !-right-9">
                <svg class="rotate-180" xmlns="http://www.w3.org/2000/svg" width="11.926"
                     height="20.737" viewBox="0 0 11.926 20.737">
                    <path id="Path_18" data-name="Path 18"
                          d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z"
                          transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                </svg>
            </button>
        </div>
    </Splide>
</template>
